.ui.button.submitButton {
  margin-top: 20px;
  width: 10%;
  background: linear-gradient(90deg,
      #5c9eec -100%,
      #001b4b 112%,
      #000935 100%);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(156 39 176 / 0%);
}

.btn.btn-link {
  font-size: 14px;
  color: white;
}

.ui.form input[type="text"].inputStyle {
  background: #5c9eec;
}

.ui.form input[type=checkbox],
.ui.form textarea {
  border-color: #5c9eec;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input .inputLabelSyle {
  color: white;
  border-color: #5c9eec;
}

.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input .parentStyle {
  background: #001b4b;
  border-color: #5c9eec;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* {for save button in parent and child mock} */
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 321px) and (min-width: 0px) {
  .ui.button.submitButton {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 115px;
  }
}

@media only screen and (max-width: 376px) and (min-width: 322px) {
  .ui.button.submitButton {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 135px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .ui.button.submitButton {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 170px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 427px) {
  .ui.button.submitButton {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 300px;
  }
}

@media only screen and (max-width: 1025px) and (min-width: 770px) {
  .ui.button.submitButton {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 440px;
  }
}

@media only screen and (max-width: 1441px) and (min-width: 1026px) {
  .ui.button.submitButton {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 630px;
  }
}

@media only screen and (max-width: 1930px) and (min-width: 1442px) {
  .ui.button.submitButton {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 875px;
  }
}

@media only screen and (max-width: 2561px) and (min-width: 1930px) {
  .ui.button.submitButton {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1180px;
  }
}

/* {parent and child dialog button} */
.addMockButton {
  margin-top: 20px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 0px) and (max-width: 599px) {
  .addMockButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .addMockButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .addMockButton {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    gap: 12px;
    margin-right: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .addMockButton {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    gap: 12px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .addMockButton {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

/* {parent button alone} */
.addParent {
  margin-top: 20px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 0px) and (max-width: 599px) {
  .addParent {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .addParent {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .addParent {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 12px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .addParent {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 12px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .addParent {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: #001b4b;
  border-color: #5c9eec;
  color: #fff !important;
}

.ui.form .field>.selection.dropdown {
  width: 100%;
  border-color: #5c9eec;
  color: #fff !important;
}

.ui.dropdown .menu .item {
  background: #001b4b;
  border-color: #5c9eec;
  color: #fff !important;
}

.ui.fluid.selection.dropdown {
  background: #001b4b;
  border-color: #5c9eec;
  color: #fff !important;
}

.ui.dropdown>.text {
  color: #fff !important;
}

.ui.dropdown .menu .item:hover span {
  filter: brightness(10%);
}

.ui.dropdown .menu .selected.item:hover {
  color: #fff !important;
  border-color: #5c9eec;
  background-color: #fff;
}