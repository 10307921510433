.feature-root {
    padding: 0 10px 100px 10px;
}

.feature-heading {
    padding: 0 0 40px 0;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500 !important;
    text-shadow: 2px 2px #5c9eec;
}