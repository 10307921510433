.ui.segment,
.ui.segments.segStyle {
  background: #5c9eec;
  background: linear-gradient(90deg,
      #5c9eec 0%,
      #5c9eec 0%,
      #001b4b 0%);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(156 39 176 / 0%);
  padding: 25px;
}

.ui.card>.content,
.ui.cards>.card>.content.cardStyle {
  background: #5c9eec;
  background: linear-gradient(90deg,
      #5c9eec -100%,
      #001b4b 112%,
      #000935 100%);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(156 39 176 / 0%);
}

.ui.blue.card,
.ui.blue.cards>.card,
.ui.cards>.blue.card.featureStyle {
  background: #5c9eec;
  background: linear-gradient(90deg,
      #5c9eec 0%,
      #001b4b 0%,
      #001b4b 0%);
  width: fit-content;
  margin-left: 115px;
  padding: 4px 0px 0px 0px;
}

.ui.blue.card,
.ui.blue.cards>.card,
.ui.cards>.blue.card.aboutStyle {
  background: #5c9eec;
  background: linear-gradient(90deg,
      #5c9eec 0%,
      #001b4b 0%,
      #001b4b 0%);
  width: 900px;
  margin-left: 115px;
  padding: 4px 0px 0px 0px;
}

.ui.vertical.center.aligned.segment.mainSection {
  margin-top: -120px;
  background-image: url("../img/sec.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.ui[class*="left aligned"].container {
  margin-top: 19pc;
}

@media screen and (min-width: 480px) {
  .ui.vertical.center.aligned.segment {
    background-image: url("../img/sec.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat;
  }

  .ui.raised.card,
  .ui.raised.cards>.card {
    width: auto;
  }

  .ui.blue.card {
    width: auto;
  }

  .ui[class*="vertically divided"].grid>.row:first-child>.column {
    margin-left: -41px;
  }
}

/*Iphone Generations*/
@media screen and (min-width: 375px) {
  .ui.vertical.center.aligned.segment {
    margin-top: -100px;
    background-image: url("../img/sec.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat;
  }

  .ui.raised.card,
  .ui.raised.cards>.card {
    width: auto;
  }

  .ui.blue.card {
    width: auto;
  }

  .ui[class*="vertically divided"].grid>.row:first-child>.column {
    margin-left: -45px;
  }
}

@media screen and (min-width: 320px) {
  .ui.vertical.center.aligned.segment {
    margin-top: -100px;
    background-image: url("../img/sec.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat;
  }

  .ui.raised.card,
  .ui.raised.cards>.card {
    width: auto;
  }

  .ui.blue.card {
    width: auto;
  }

  .ui[class*="vertically divided"].grid>.row:first-child>.column {
    margin-left: -45px;
  }
}