.collapseStyle {
  background: #5c9eec;
  background: linear-gradient(
    90deg,
    #5c9eec -100%,
    #001b4b 112%,
    #000935 100%
  );
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgb(156 39 176 / 0%);
  border-radius: 6px;
  padding: 1px 1px 1px 1px;
  padding-bottom: 130px;
  margin-left: -110px;
  color: white;
  font-weight: bold;
  max-width: 260px;
  max-height: 60px;
  min-width: 160px;
  min-height: 50px;
}
.collapseStyleChild {
  background: #5c9eec;
background: linear-gradient(
  90deg,
  #5c9eec -100%,
  #001b4b 112%,
  #000935 100%
);
box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
  0 7px 10px -5px rgb(156 39 176 / 0%);
border-radius: 6px;
padding: 1px 1px 1px 1px;
padding-bottom: 130px;
margin-left: -110px;
color: white;
font-weight: bold;
max-width: 260px;
max-height: 60px;
min-width: 160px;
min-height: 50px;

}
.btn.btn-link {
  font-size: 14px;
  color: white;
}

.ui.container.mockbody {
  width: 1276px;
}

.tabStyle {
  background: #5c9eec;
  background: linear-gradient(
    90deg,
    #5c9eec 0%,
    #5c9eec 0%,
    #001b4b 0%
  );
}
