.intro-root {
    box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 1);
    background-image: url("../../img/banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
}

.intro-root1 {
    background: rgba(0, 0, 0, .5);
}

.intro-grid {
    padding: 40px;
}

.intro-heading {
    padding: 40px 0 40px 0;
    color: #ffffff;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500 !important;
    text-shadow: 2px 2px #5c9eec;
}

.intro-text {
    color: #ffffff;
    text-shadow: 2px 2px #000000;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
}

.intro-bullet {
    color: #f50d57;
}

@media only screen and (max-width: 890px) and (min-width: 0px) {
    .intro-text {
        font-size: 16px !important;
        word-spacing: -2px;
    }
}

@media only screen and (min-width: 910px) {
    .intro-text {
        font-size: 20px !important;
    }
}