.mockStyle {
    background: #5c9eec ;
    background: linear-gradient(45deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 100%);
    box-Shadow: 0 8px 40px 0 rgba(0,0,0,1);
    padding: 25px;
}

.buttonStyle {
    background: #5c9eec ;
    background: linear-gradient(45deg, rgba(0,27,75,1) 0%, rgba(0,9,53,1) 100%);
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgb(156 39 176 / 0%) ;
}

