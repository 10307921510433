.MuiDialogTitle-root.dialogHeader {
  background: #b9d9eb;
}

.MuiDialogContent-root.content {
  background: #b9d9eb;
}

.MuiDialogActions-root.actions {
  background: #b9d9eb;
}
